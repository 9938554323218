.gp-title-h1 {
  font-family: "Yekan Bakh", sans-serif;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
}

.gp-title-h2 {
  font-family: "Yekan Bakh", sans-serif;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}

.gp-title-h3 {
  font-family: "Yekan Bakh", sans-serif;
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
}

.gp-title-h4 {
  font-family: "Yekan Bakh", sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

.gp-title-h5 {
  font-family: "Yekan Bakh", sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.gp-title-h6 {
  font-family: "Yekan Bakh", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.gp-title-h7 {
  font-family: "Yekan Bakh", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.gp-title-h8 {
  font-family: "Yekan Bakh", sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

.gp-heading-h5 {
  font-family: "Yekan Bakh", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

:root {
  --font-size-s: 12px;
  --font-size-sx: 14px;
  --font-size-m: 16px;
  --font-size-l: 20px;
  --font-size-xl: 24px;
  --font-size-xlx: 26px;
  --font-size-xxl-2: 32px;
  --font-size-xxl-4: 40px;
}
