:root {
  --c-white: #fff;
  --c-black: #000;

  --c-text-primary: #0a0a0a;
  --c-text-secondary: #3c3c3c;
  --c-text-tertiary: #878787;

  --c-bg: var(--c-white);

  --c-primary-0: #426604;
  --c-primary-10: #5B8B02;
  --c-primary-20: #9CB100;
  --c-primary-30: #AEC503;
  --c-primary-40: #C4E201;
  --c-primary-50: #D5EC2C;
  --c-primary-60: #AFF024;
  --c-primary-70: #BBEF50;
  --c-primary-80: #D4F492;
  --c-primary-90: #E3F8BE;
  --c-primary-95: #F6FFE5;
  --c-primary-100: #FEFFFB;

  --c-secondary-0: #02009C;
  --c-secondary-10: #0200B5;
  --c-secondary-20: #3400C4;
  --c-secondary-30: #4700CB;
  --c-secondary-40: #5900D4;
  --c-secondary-50: #5900D4;
  --c-secondary-60: #8034E8;
  --c-secondary-70: #9A5AF6;
  --c-secondary-80: #B98BFF;
  --c-secondary-90: #D6BAFE;
  --c-secondary-95: #F0E3FF;
  --c-secondary-98: #FAF5FF;

  --c-gray-0: #292F51;
  --c-gray-5: #373F6D;
  --c-gray-10: #4B5695;
  --c-gray-20: #5C68AD;
  --c-gray-30: #7781BB;
  --c-gray-40: #939BC8;
  --c-gray-50: #AEB4D5;
  --c-gray-60: #C9CDE3;
  --c-gray-70: #D6D9EA;
  --c-gray-80: #E4E6F1;
  --c-gray-90: #F1F2F8;
  --c-gray-95: #F8F9FD;
  --c-gray-100: #FFFFFF;

  --c-dark-0: #0E101B;
  --c-dark-10: #151829;
  --c-dark-30: #1B1F36;
  --c-dark-40: #222744;
  --c-dark-50: #292F51;

  --c-error-0: #000;
  --c-error-10: #410002;
  --c-error-20: #690005;
  --c-error-30: #93000a;
  --c-error-40: #ba1a1a;
  --c-error-50: #de3730;
  --c-error-60: #ff5449;
  --c-error-70: #ff897d;
  --c-error-80: #ffb4ab;
  --c-error-90: #ffdad6;
  --c-error-95: #ffedea;
  --c-error-99: #fffbff;
  --c-error-100: #fff;
}
