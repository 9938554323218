@font-face {
  font-family: Yekan Bakh ExtraCondensed;
  font-style: normal;
  font-weight: 100;
  font-stretch: extra-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-ThinExtraCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ThinExtraCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraCondensed;
  font-style: normal;
  font-weight: 300;
  font-stretch: extra-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-LightExtraCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-LightExtraCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraCondensed;
  font-style: normal;
  font-weight: normal;
  font-stretch: extra-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-RegularExtraCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-RegularExtraCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraCondensed;
  font-style: normal;
  font-weight: 600;
  font-stretch: extra-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-SemiBoldExtraCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-SemiBoldExtraCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraCondensed;
  font-style: normal;
  font-weight: bold;
  font-stretch: extra-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-BoldExtraCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-BoldExtraCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraCondensed;
  font-style: normal;
  font-weight: 800;
  font-stretch: extra-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBoldExtraCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBoldExtraCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraCondensed;
  font-style: normal;
  font-weight: 900;
  font-stretch: extra-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-BlackExtraCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-BlackExtraCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraCondensed;
  font-style: normal;
  font-weight: 950;
  font-stretch: extra-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBlackExtraCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBlackExtraCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Condensed;
  font-style: normal;
  font-weight: 100;
  font-stretch: condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-ThinCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ThinCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Condensed;
  font-style: normal;
  font-weight: 300;
  font-stretch: condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-LightCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-LightCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Condensed;
  font-style: normal;
  font-weight: normal;
  font-stretch: condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-RegularCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-RegularCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Condensed;
  font-style: normal;
  font-weight: 600;
  font-stretch: condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-SemiBoldCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-SemiBoldCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Condensed;
  font-style: normal;
  font-weight: bold;
  font-stretch: condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-BoldCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-BoldCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Condensed;
  font-style: normal;
  font-weight: 800;
  font-stretch: condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBoldCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBoldCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Condensed;
  font-style: normal;
  font-weight: 900;
  font-stretch: condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-BlackCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-BlackCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Condensed;
  font-style: normal;
  font-weight: 950;
  font-stretch: condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBlackCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBlackCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiCondensed;
  font-style: normal;
  font-weight: 100;
  font-stretch: semi-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-ThinSemiCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ThinSemiCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiCondensed;
  font-style: normal;
  font-weight: 300;
  font-stretch: semi-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-LightSemiCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-LightSemiCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiCondensed;
  font-style: normal;
  font-weight: normal;
  font-stretch: semi-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-RegularSemiCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-RegularSemiCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiCondensed;
  font-style: normal;
  font-weight: 600;
  font-stretch: semi-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-SemiBoldSemiCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-SemiBoldSemiCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiCondensed;
  font-style: normal;
  font-weight: bold;
  font-stretch: semi-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-BoldSemiCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-BoldSemiCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiCondensed;
  font-style: normal;
  font-weight: 800;
  font-stretch: semi-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBoldSemiCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBoldSemiCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiCondensed;
  font-style: normal;
  font-weight: 900;
  font-stretch: semi-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-BlackSemiCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-BlackSemiCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiCondensed;
  font-style: normal;
  font-weight: 950;
  font-stretch: semi-condensed;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBlackSemiCondensed.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBlackSemiCondensed.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh;
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
  src: url('../../fonts/yekan/woff/YekanBakh-Thin.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-Thin.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  src: url('../../fonts/yekan/woff/YekanBakh-Light.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-Light.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  src: url('../../fonts/yekan/woff/YekanBakh-Regular.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-Regular.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url('../../fonts/yekan/woff/YekanBakh-SemiBold.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  src: url('../../fonts/yekan/woff/YekanBakh-Bold.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-Bold.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBold.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  src: url('../../fonts/yekan/woff/YekanBakh-Black.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-Black.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh;
  font-style: normal;
  font-weight: 950;
  font-stretch: normal;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBlack.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBlack.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiExpanded;
  font-style: normal;
  font-weight: 100;
  font-stretch: semi-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-ThinSemiExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ThinSemiExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiExpanded;
  font-style: normal;
  font-weight: 300;
  font-stretch: semi-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-LightSemiExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-LightSemiExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiExpanded;
  font-style: normal;
  font-weight: normal;
  font-stretch: semi-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-RegularSemiExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-RegularSemiExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiExpanded;
  font-style: normal;
  font-weight: 600;
  font-stretch: semi-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-SemiBoldSemiExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-SemiBoldSemiExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiExpanded;
  font-style: normal;
  font-weight: bold;
  font-stretch: semi-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-BoldSemiExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-BoldSemiExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiExpanded;
  font-style: normal;
  font-weight: 800;
  font-stretch: semi-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBoldSemiExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBoldSemiExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiExpanded;
  font-style: normal;
  font-weight: 900;
  font-stretch: semi-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-BlackSemiExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-BlackSemiExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh SemiExpanded;
  font-style: normal;
  font-weight: 950;
  font-stretch: semi-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBlackSemiExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBlackSemiExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Expanded;
  font-style: normal;
  font-weight: 100;
  font-stretch: expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-ThinExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ThinExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Expanded;
  font-style: normal;
  font-weight: 300;
  font-stretch: expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-LightExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-LightExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Expanded;
  font-style: normal;
  font-weight: normal;
  font-stretch: expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-RegularExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-RegularExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Expanded;
  font-style: normal;
  font-weight: 600;
  font-stretch: expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-SemiBoldExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-SemiBoldExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Expanded;
  font-style: normal;
  font-weight: bold;
  font-stretch: expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-BoldExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-BoldExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Expanded;
  font-style: normal;
  font-weight: 800;
  font-stretch: expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBoldExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBoldExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Expanded;
  font-style: normal;
  font-weight: 900;
  font-stretch: expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-BlackExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-BlackExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh Expanded;
  font-style: normal;
  font-weight: 950;
  font-stretch: expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBlackExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBlackExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraExpanded;
  font-style: normal;
  font-weight: 100;
  font-stretch: extra-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-ThinExtraExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ThinExtraExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraExpanded;
  font-style: normal;
  font-weight: 300;
  font-stretch: extra-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-LightExtraExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-LightExtraExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraExpanded;
  font-style: normal;
  font-weight: normal;
  font-stretch: extra-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-RegularExtraExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-RegularExtraExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraExpanded;
  font-style: normal;
  font-weight: 600;
  font-stretch: extra-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-SemiBoldExtraExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-SemiBoldExtraExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraExpanded;
  font-style: normal;
  font-weight: bold;
  font-stretch: extra-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-BoldExtraExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-BoldExtraExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraExpanded;
  font-style: normal;
  font-weight: 800;
  font-stretch: extra-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBoldExtraExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBoldExtraExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraExpanded;
  font-style: normal;
  font-weight: 900;
  font-stretch: extra-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-BlackExtraExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-BlackExtraExpanded.woff2') format('woff2');
}

@font-face {
  font-family: Yekan Bakh ExtraExpanded;
  font-style: normal;
  font-weight: 950;
  font-stretch: extra-expanded;
  src: url('../../fonts/yekan/woff/YekanBakh-ExtraBlackExtraExpanded.woff') format('woff'),
  url('../../fonts/yekan/woff2/YekanBakh-ExtraBlackExtraExpanded.woff2') format('woff2');
}
