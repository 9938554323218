@tailwind base;
@tailwind utilities;

@import "assets/styles/reset.css";
@import "assets/styles/spacing.css";
@import "assets/styles/variables.scss";

@import "assets/styles/typography/font-faces.css";
@import "assets/styles/typography/headings.css";
@import "assets/styles/typography/bodies.css";

@import "assets/styles/colors/light_colors.scss";
@import "assets/styles/colors/material_theme.scss";
@import "assets/styles/colors/colors.css";
@import "assets/styles/breakpoints";
@import "assets/styles/mixins";

html {
  scroll-behavior: smooth;
}

button {
  @apply whitespace-nowrap;
}

.send-message {
  @apply flex;
  cursor: pointer;
  color: var(--c-secondary-10);

  &:hover {
    color: var(--c-secondary-80);
  }
}
